import Vue from 'vue'
import VueRouter from 'vue-router'
import layout from '../layout/index.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'layout',
    component: layout,
    children: [
      {
        path: '/',
        name: 'home',
        component: () => import('../views/HomeView.vue')
      },
      {
        path: '/contactus',
        name: 'contactus',
        component: () => import(/* webpackChunkName: "linkEmail" */ '../views/linkEmail.vue')
      }
    ]
  }

]

const router = new VueRouter({
  base: process.env.BASE_URL,
  mode: 'history',
  routes
})

export default router
