<template>
<div class="layout">
  <div class="nav scale-in-left">
    <div class="menuMobile scale-in-left" v-if="menuMobile">
      <div class="menuList" v-for="i in menuList" :key="i.name">
        {{i.name}}
      </div>
    </div>
    <div class="logo">
      <img :src="require('@/assets/logo.png')" alt="">
    </div>
    <div class="menu" @click="$router.push('contactus')">
      CONTACT US
<!--      <router-link tag="a" target="_blank" :to="{name:'linkEmail'}"> CONTACT US</router-link>-->

<!--      <div class="menuList" @click="handleClickRouter(i)" v-for="i in menuList" :key="i.name">-->
<!--        {{i.name}}-->
<!--      </div>-->
    </div>
    <div class="menuLogo" @click="handleClickM">
      <img :src="require('@/assets/menu.png')" alt="">
    </div>
  </div>
  <div class="content">
    <transition :name="currentRoute === 'home' ? 'bottomToBottom' : ''">
      <router-view/>
    </transition>
  </div>

</div>
</template>

<script>
import { mapState } from 'vuex'
import ResizeMixin from './mixin/ResizeHandler'
export default {
  name: 'layoutIndex',
  data () {
    return {
      menuList: [
        {
          name: 'Play',
          path: '/home'
        },
        {
          name: 'Create',
          path: '/about'
        },
        {
          name: 'Download',
          path: '/'
        },
        {
          name: 'About',
          path: '/'
        },
        {
          name: 'Contact us',
          path: '/'
        }
      ],
      menuMobile: false
    }
  },

  mixins: [ResizeMixin],
  computed: {
    currentRoute () {
      return this.$route.name
    },
    ...mapState({
      device: state => state.device
    })
  },
  methods: {
    handleClickRouter (item) {
      this.$router.push(item.path)
    },
    handleClickM () {
      this.menuMobile = !this.menuMobile

      if (this.menuMobile) {
        //   滚动锁定
        document.body.style.overflow = 'hidden'
      } else {
        document.body.style.overflow = 'auto'
      }
    },
    handle () {
    //   打开新的窗口

    }
  }
}
</script>

<style scoped lang="less">
.layout {
  width: 100%;
  height: 100%;
  //position: relative;
}
// 手机端
@media screen and (max-width: 768px) {
  .menuMobile {
    position: absolute;
    top: 3.75rem;
    background: rgba(0, 0, 0);
    //background: red;
    height: 100vh;
    width: 100%;
    z-index: 99999;
    padding-top: 8rem;
    .menuList {
      font-size: 1.25rem;
      font-family: Montserrat, Montserrat-SemiBold;
      font-weight: normal;
      text-align: center;
      color: #ffffff;
      margin-bottom: 3rem;
    }
  }
  .nav {
    height: 3.75rem;
    width: 100%;

    // 透明 模糊
    background: rgba(0, 0, 0, 0.5);
    backdrop-filter: blur(2.25rem);
    position: fixed;
    display: flex;
    //justify-content: center;
    //align-items: center;
    color: #fff;
    z-index: 999;
    top: 0;
    left: 0;

  }
  .logo {
    width: 10rem;
    //position: relative;
    transform: scale(0.6);
    margin-left: -1rem;
    //left: 0.5rem;
    img {
      display: inline-block;
      width: 100%;
      height: 100%;
    }
  }
  .menu {
    //display: none;
    width: 7rem;
    height: 2rem;
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
    background: linear-gradient(287deg,#00867f 12%, #4fe073 100%);
    border-radius: 1.3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.7rem!important;
    padding: 0 1rem;
    font-family: Montserrat, Montserrat-SemiBold;

  }
  .menuLogo {
    display: none;
    //width: 1.5rem;
    //height: 1.5rem;
    //position: absolute;
    //right: 3%;
    //top: 50%;
    //transform: translateY(-50%);
    //
    //img {
    //  display: inline-block;
    //  width: 100%;
    //  height: 100%;
    //}
  }
}
// 电脑端
@media screen and (min-width: 768px) {
  .menuMobile {
    display: none;
  }

  .nav {

    height: 5rem;
    z-index: 999;
    // 透明 模糊
    background: rgba(0, 0, 0, 0.1);
    backdrop-filter: blur(2.25rem);
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .menuLogo {
      display: none;
    }
    .logo {
      color: #fff;
      padding-left: 5.36rem;
      img {
        display: inline-block;
        width: 10rem;
      }
      .logoImage {
        display: flex;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
      }
    }
    .menu {
      width: 16.75rem;
      height: 3.63rem;
      margin-right: 4.6rem;
      cursor: pointer;
      background: linear-gradient(287deg,#00867f 12%, #4fe073 100%);
      border-radius: 1.81rem;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.5rem;
      font-family: Montserrat, Montserrat-SemiBold;
      font-weight: normal;
      text-align: left;
      color: #ffffff;
      // 变小
      transform: scale(0.6);
    }
  }
}

.content {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}
</style>
