<template>
  <div class="cus-loading" v-if="flag" @click.prevent.stop @touchstart.prevent.stop @touchmove.prevent.stop>
  </div>
</template>

<script>
export default {
  data () {
    return {
      flag: false,
      iconFlag: true,
      txtFlag: true,
      title: ''
    }
  },
  props: {

  },
  methods: {
    open () {
      this.flag = true
    },
    close () {
      this.flag = false
    },
    // 可以绑定这个阻止默认行为和冒泡，上边直接使用的修饰符
    stopPropagation (e) {
      e.stopPropagation()
      e.preventDefault()
      return false
    }
  }
}
</script>

<style lang="less" scoped>
.cus-loading{
  width: 100%;
  height: 100%;
  position: fixed;
  top:100%;
  z-index: 99;
  background: #00837c;
  animation: loading 1s linear;
}
// 全屏由黄色的背景变成红色动画
@keyframes loading {
  0% {
   top: 100%;
  }
  100% {
    top:0;
  }
}
</style>
