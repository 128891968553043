<template>
  <div id="app">
      <router-view/>
  </div>
</template>

<style lang="less">
.imageIcon-content {
  position: fixed;
  bottom: 0;
  right: 0;
  margin: 3.06rem;
  display: flex;
}
// 手机端
@media screen and (max-width: 768px) {
  .anchor {
    position: fixed;
    left: 0;
    z-index: 4;
    height: 100%;
  }
  .down {
    position: relative;
    bottom: 4rem;
    right: 50%;
    z-index: 99;
    width: 100%;
    transform: translateX(50%);
    display: flex;
    align-items: center;
    justify-content: center;
    .arrowImg{
      display: inline-block;
      width: 2rem;
      height: 2rem;
    }
  }
}
// 电脑端
@media screen and (min-width: 768px) {
  .anchor {
    position: fixed;
    left: 0;
    z-index: 4;
    height: 100%;
  }
  .down {
    display: none;
  }
}

</style>
<script>
// import ImageContent from '@/components/imageContent.vue'
import { mapGetters } from 'vuex'
import ResizeMixin from '@/layout/mixin/ResizeHandler'
import store from '@/store'
const { body } = document
const WIDTH = 992

export default {
  components: {
    // ImageContent,
  },
  directives: {
    move: {
      bind: (el, binding) => {
        /**
         *  判断是PC端还是移动端
         *  通过不同的设备定义不同的事件名
         */
        const isMobile =
          navigator.userAgent.match(
            /(phone|pad|pod|iPhone|iPod|ios|iPad|Android|Mobile|BlackBerry|IEMobile|MQQBrowser|JUC|Fennec|wOSBrowser|BrowserNG|WebOS|Symbian|Windows Phone)/i
          ) !== null
        const [start, move, end] = isMobile
          ? ['touchstart', 'touchmove', 'touchend']
          : ['mousedown', 'mousemove', 'mouseup']
        /**
         *  开始触摸
         *  执行触摸回调函数
         *  监听滑动事件
         *  监听触摸结束事件
         *  监听阻止移动端屏幕移动事件
         */
        const startFun = e => {
          binding.value.start(e)
          el.addEventListener(move, moveFun)
          el.addEventListener(end, endFun)
          window.addEventListener('touchmove', prevent, {
            passive: false
          })
        }
        /**
         *  开始滑动
         *  执行滑动回调函数
         */
        const moveFun = e => {
          binding.value.move(e)
        }
        /**
         *  结束触摸
         *  执行结束触摸回调函数
         *  销毁滑动事件
         *  销毁结束触摸事件
         *  销毁阻止移动屏幕事件
         */
        const endFun = e => {
          binding.value.end(e)
          el.removeEventListener(move, moveFun)
          el.removeEventListener(end, endFun)
          window.removeEventListener('touchmove', prevent)
        }
        /**
         *  阻止移动端屏幕移动
         */
        const prevent = e => {
          e.preventDefault()
        }
        /**
         *  监听触摸事件
         */
        el.addEventListener(start, startFun)
      }
    }
  },
  mixins: [ResizeMixin],
  data () {
    return {
      startY: 0,
      endY: 0
    }
  },
  computed: {
    // 屏幕宽度
    ...mapGetters(['device'])
    // 上下滑动

  },
  created () {
  },
  mounted () {
    // console.log(this.device
    const isMobile = this.$_isMobile()
    if (isMobile) {
      this.$store.dispatch('toggleDevice', 'mobile')
    }
  },
  methods: {
  //   点击到下个页面
    handleClick () {
      if (this.$route.name === 'home') {
        this.$router.push({ name: 'about' })
      } else if (this.$route.name === 'about') {
        this.$router.push({ name: 'changeCard' })
      } else if (this.$route.name === 'changeCard') {
        this.$router.push({ name: 'changeCri' })
      } else if (this.$route.name === 'changeCri') {
        this.$router.push({ name: 'changeSwiper' })
      } else {
        this.$router.push({ name: 'home' })
      }
    },
    $_isMobile () {
      const rect = body.getBoundingClientRect()
      return rect.width - 1 < WIDTH
    },

    swipeDown () {
      if (this.$route.name === 'home') {
        this.$router.push({ name: 'about' })
      } else if (this.$route.name === 'about') {
        this.$router.push({ name: 'changeCard' })
      } else if (this.$route.name === 'changeCard') {
        this.$router.push({ name: 'changeCri' })
      } else if (this.$route.name === 'changeCri') {
        this.$router.push({ name: 'changeSwiper' })
      } else {
        this.$router.push({ name: 'home' })
      }
      // 往上滑
    },
    swipeUp () {
      if (this.$route.name === 'home') {
        this.$router.push({ name: 'changeSwiper' })
      } else if (this.$route.name === 'about') {
        this.$router.push({ name: 'home' })
      } else if (this.$route.name === 'changeCard') {
        this.$router.push({ name: 'about' })
      } else if (this.$route.name === 'changeCri') {
        this.$router.push({ name: 'changeCard' })
      } else {
        this.$router.push({ name: 'changeCri' })
      }
    },
    // 节流
    throttle (fn, delay) {
      let timer = null
      return function () {
        const context = this
        const args = arguments
        if (!timer) {
          timer = setTimeout(function () {
            fn.apply(context, args)
            timer = null
          }, delay)
        }
      }
    },
    start (e) {
      this.startY = e.touches[0].clientY
    },
    move (e) {
      this.endY = e.touches[0].clientY
      const distance = this.endY - this.startY
      // if (distance > 0) {
      //   this.swipeDown()
      // } else {
      //   this.swipeUp()
      // }
    },
    end (e) {
      this.endY = e.changedTouches[0].clientY
      const distance = this.endY - this.startY

      if (distance > 50) {
        this.swipeUp()
      } else if (distance < -50) {
        this.swipeDown()
      }
    }

  }
}

</script>
